.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#tsparticles {
  position: fixed;
  height: 70%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
}

/* .sneaky-background {
  background-image: url("images/WinterDTF-Clean_no_bg_compressed.webp");
  background-position: 50% 35%;
  background-repeat: no-repeat;
  background-size: 95%;
  width: 100vw;
  height: 100vh;
  position: absolute;
} */

.spotlight {
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: radial-gradient(
    circle,
    transparent 0px,
    rgba(0, 0, 0, 0.95) 1px
  );
}

.particle-canvas {
  position: absolute !important;
}

.logo-container {
  position: absolute;
  bottom: 0;
  height: 90%;
}

html,
body {
  margin: 0;
}

body {
  background-color: #0c0c0c;
  font-family: "Raleway", sans-serif;
}

h2 {
  color: #ffffff;
  margin: 0;
  padding: 50px 0 50px;
  background-color: #0c0c0c;
  font-weight: 200;
  font-size: 36px;
  text-align: center;
  width: 100%;
}

a {
  color: #6fda66;
  margin-right: 6px;
  text-decoration: none;
}

#header {
  background-color: #0c0c0c;
  height: 100vh;
  z-index: -5;
  border-bottom: 1px white solid;
}

.main-title {
  position: absolute;
  left: calc(50vw - 104px);
  top: 150px;
  font-size: 36px;
  font-weight: 200;
  color: white;
}

.main-title-container {
  display: flex;
  justify-content: center;
}

.small-photo {
  width: 100vw;
  position: absolute;
  bottom: 0;
}

.photo {
  position: absolute;
  display: none;
  bottom: 0;
}

#main-menu {
  background: #0c0c0c;
  display: flex;
  height: 50px;
  justify-content: center;
  margin: 0 auto;
  position: fixed;
  top: 0px;
  transition-duration: 0.5s;
  width: 100%;
  z-index: 2;
}

.menu-links {
  color: #ffffff;
  font-weight: 400px;
  padding: 15px 7.5%;
  text-decoration: none;
}

.menu-links:hover {
  color: #aaaaaa;
  transition-duration: 0.25s;
}

#main-menu-social {
  display: none;
  color: white;
}

#tour {
  margin: 0 0 -4px 0;
}

#tour-date-container {
  margin: 0 5% 100px;
}

.widget-container {
  display: inline-block;
  width: 100%;
  height: 480px;
  margin: 0;
  padding: 0;
  border: 0;
}

.tour-widget {
  width: 0px;
  min-width: 100%;
  max-width: 100%;
}

#bio {
  background-position: center top;
  margin: 0 auto;
  padding: 1rem;
}

.bio-text {
  color: #ffffff;
  font-family: "Titillium Web", sans-serif;
  font-weight: 200;
  line-height: 1.75;
  margin: 0 5%;
  text-align: center;
}

#lets-rage {
  color: #ffffff;
  font-family: "Titillium Web", sans-serif;
  font-size: 20px;
  font-weight: 200;
  line-height: 1.75;
  margin: 0 auto;
  padding: 120px 5% 0;
  text-align: center;
}

#music {
  background-image: url("https://res.cloudinary.com/dkf9qmqxa/image/upload/q_auto:eco/v1705945336/AJ8A1852_uvau2o.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 56vh;
  margin: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#videos {
  background: black;
  margin: 0;
  text-align: center;
}

#music-spacer {
  height: 135px;
}

#music-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 400px auto;
}

.hidden-tunes,
.hidden-tunes-2,
.hidden-tunes-3 {
  display: none;
}

.music-text {
  margin-top: 60px;
}

.music-links {
  color: #ffffff;
  font-family: "Titillium Web", sans-serif;
  font-weight: 900;
  font-size: 26px;
  text-decoration: none;
  background: #41455791;
  background-color: #292828a3;
  padding: 0px 10px;
  border: 3px solid black;
  border-radius: 25px;
}

.music-links:hover {
  color: black;
  background: #d4d4d485;
}

#contact {
  background-color: #ffffff;
  padding: 30px 0 30px;
  text-align: center;
  width: 100%;
}

#contact-title {
  color: #0c0c0c;
  background-color: #ffffff;
  padding-top: 0;
  padding-bottom: 20px;
}

.contact-text {
  padding: 0 0 0 6%;
  font-size: 20px;
  font-weight: 200;
  line-height: 1.7;
  width: 90%;
}

.contact-links {
  font-weight: 400;
  color: #333333;
  text-decoration: none;
}

.parallax-scrolling {
  margin: 0;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#social-icon-container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 320px;
}

.social-icon {
  margin: 30px 15px;
  width: 60px;
  height: 60px;
}

.social-icon:hover {
  opacity: 0.8;
}

.music-player {
  width: 90vw;
}

.video-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

iframe.music-videos {
  margin: 30px;
}

.iframe-container {
  width: 70%;
  margin: auto;
}

.main-social-links {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white !important;
}

.sneaky-background {
  display: none;
}
@keyframes zoomIn {
  0% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(1);
  }
}
.card-logo {
  width: 100vw;
  height: 100vh;
  /* margin: 184px 0; */
  height: unset;
  animation: zoomIn 8s;
}
.logo-container {
  right: 0;
  height: calc(100vh - 90px);
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 500px) {
  .sneaky-background {
    display: none;
  }
  @keyframes zoomIn {
    0% {
      transform: scale(0.2);
    }
    100% {
      transform: scale(1);
    }
  }
  .card-logo {
    width: 100vw;
    /* margin: 184px 0; */
    height: unset;
    animation: zoomIn 8s;
  }
  .logo-container {
    right: 0;
    height: calc(100vh - 50px);
  }
}
@media screen and (min-width: 500px) {
  #main-menu {
    height: 50px;
    display: flex;
    margin: 0 auto;
    position: fixed;
    top: 0px;
    width: 100%;
  }

  .logo {
    width: 300px;
    position: absolute;
    top: 50px;
    left: calc(50vw - 150px);
  }

  .music-text {
    margin-top: 20px;
  }

  .social-icon {
    margin: 30px 20px;
    width: 75px;
  }

  /*@keyframes menu-background {
      0% {background-color: #A3ADB8};
      30% {background-color: #83929F};
      60% {background-color: #4E6172};
      90% {background-color: #A3ADB8};
    }*/
}

@media screen and (min-width: 800px) {
  .photo {
    width: 100vw;
    margin: 100px calc(50vw - 45vh) 0 0;
    display: inline;
  }

  .main-title {
    top: calc(30vh + 22px);
    left: calc(50vw - 104px);
  }

  .small-photo {
    display: none;
  }

  #main-menu-social {
    display: inline-flex;
    padding: 15px;
    right: 25px;
    z-index: 12;
  }

  .music-player {
    width: 700px;
  }

  .menu-social-icon {
    margin: 0 5px;
    height: 15px;
    transition-duration: 0.25s;
    z-index: 12;
    width: 16px;
  }

  .menu-social-icon:hover {
    opacity: 0.5;
    transition-duration: 0.25s;
  }

  .bio-text {
    margin: 0 15%;
    font-size: 20px;
  }

  #lets-rage {
    font-size: 24px;
    padding-top: 100px;
    position: static;
  }

  .hidden-tunes {
    display: inline-block;
  }

  .social-icon-container {
    width: 600px;
  }
}

@media screen and (min-width: 1000px) {
  .logo {
    top: calc(50vh - 109px);
    left: calc(50vw - 150px);
  }
}

@media screen and (min-width: 1300px) {
  .main-title {
    top: calc(50vh + 22px);
    left: 10vw;
  }

  .logo {
    position: absolute;
    left: 10vw;
  }

  .photo {
    width: auto;
    height: calc(100vh - 100px);
    margin: auto;
  }
}

@media screen and (max-width: 768px) {
  #music {
    background-position: center center;
    background-attachment: scroll;
    height: 40vh; /* Shorter height for mobile */
  }
}
